.login-form-2 .form-control{
    background-color:rgba(0,0,0,0) !important;
    
    
}
.login-container{
    margin-top: 15%;
    justify-content: center;
}
.login-form-1{
    border-radius: 9px;
    box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.2), 0 20px 26px 0 rgba(0, 0, 0, 0.19);
    padding: 2%;
    background: #ffffff;
}
.login-form-1 h3{
    text-align: center;
    color: #283040;
}
.login-form-2{
    border-radius: 9px;
    padding: 2%;
    background: #f7f7f700;
}
.login-form-2 h3{
    text-align: center;
    color: #fff;
}
.login-container form{
    padding: 10%;
}
.btnSubmit
{
    width: 100%;
    border-radius: 50rem  !important;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}
.login-form-1 .btnSubmit{
    color: #fff;
    background-color: #0062cc;
}
.login-form-2 .btnSubmit{
    background-color: #000;
    color: #0062cc;
    border-radius: 0rem;
}


hr { display: block; 
height: 1px;
border: 0; 
border-top: 1px solid rgb(109, 179, 201);
margin: 1em 0; 
padding: 0; }
.btn-secondary{
    background-color: #283040;
}
.fondo{
    background-image: url('./fondo.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center fixed;
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
        
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
        
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}
.matilogo{
    display: flex;
  justify-content: center;
  text-align: center;
}
.parche{
 left: 13vw;
}
.MAS{
    color: white;
    padding-top: -10rem;
    font-family: 'Nunito', sans-serif;
}
.form-control1::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(255, 255, 255);
    opacity: 1; /* Firefox */
}
.form-control1 { /* Chrome, Firefox, Opera, Safari 10.1+ */
    border-radius: 0rem;
    border-left:transparent;
    border-right:transparent;
    border-top:transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-left:transparent !important;
    border-right:transparent !important;
    border-top:transparent !important;
    border: 1px solid rgb(255, 255, 255);
    -webkit-text-fill-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 0px 1000px #2065b900 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.kenburns-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
}
    .kenburns {
      
      position: absolute;
      top: -999px;
      bottom: -999px;
      left: -999px;
      right: -999px;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
      margin: auto;
          animation: 45s ppb_kenburns linear infinite alternate;
    }
  
  
  @keyframes ppb_kenburns{
      0% {
          transform: scale(1.3) translate(-10%, 10%);
      }
      25% {
          transform: scale(1) translate(0, 0);
      }
      50% {
          transform: scale(1.3) translate(10%, 10%);
      }
      75% {
          transform: scale(1) translate(0, 0);
      }
      100% {
          transform: scale(1.3) translate(-10%, 10%);
      }
  }