.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
.container2 {
  width: 180px;
  height: auto;
  transform: rotate(-90deg);
  transform-origin: center;
}

.content2 {
  width:auto;
  height: 140px;
  transform: rotate(90deg);
}
.card-imagen{
    display: flex;
   align-items: center;
}
.card{
  background-color: #283040;
  border-color: #d5f26d
}
.custom {
  width: 100px !important;
}