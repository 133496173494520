.reloj{
    color: rgb(255, 255, 255);
    font-size: 400%;
    font-family: 'Nunito', sans-serif;
}
.table{
    background-color: #283040;
}
.tr{
    background-color: #283040;
}
.picker{
    background-color: #fafafa;

}