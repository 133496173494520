@keyframes ldio-n3f301ff53 {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
  .ldio-n3f301ff53 > div > div {
    transform-origin: 100px 100px;
    animation: ldio-n3f301ff53 3.0303030303030303s linear infinite;
    opacity: 0.8
  }
  .ldio-n3f301ff53 > div > div > div {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 70px;
    height: 70px;
    border-radius: 70px 0 0 0;
    transform-origin: 100px 100px
  }.ldio-n3f301ff53 > div div:nth-child(1) {
    animation-duration: 0.7575757575757576s
  }
  .ldio-n3f301ff53 > div div:nth-child(1) > div {
    background: #337ab7;
    transform: rotate(0deg);
  }.ldio-n3f301ff53 > div div:nth-child(2) {
    animation-duration: 1.0101010101010102s
  }
  .ldio-n3f301ff53 > div div:nth-child(2) > div {
    background: #5bc0de;
    transform: rotate(0deg);
  }.ldio-n3f301ff53 > div div:nth-child(3) {
    animation-duration: 1.5151515151515151s
  }
  .ldio-n3f301ff53 > div div:nth-child(3) > div {
    background: #5cb85c;
    transform: rotate(0deg);
  }.ldio-n3f301ff53 > div div:nth-child(4) {
    animation-duration: 3.0303030303030303s
  }
  .ldio-n3f301ff53 > div div:nth-child(4) > div {
    background: #d9534f;
    transform: rotate(0deg);
  }
  .loadingio-spinner-wedges-lvg3siqmxif {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #283040;
  }
  .ldio-n3f301ff53 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-n3f301ff53 div { box-sizing: content-box; }
  /* generated by https://loading.io/ */